<script>
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import invoke from 'lodash/invoke';
import get from 'lodash/get';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import { mapGetters, mapState } from 'vuex';
import {
  MuiInputText,
  Validate,
} from '@emobg/motion-ui/v1';
import { everyValue } from '@emobg/web-utils';
import { toDecimalFormatOrNull } from '@domains/Pricing/utils/pricing.utils';
import { NUMERIC_INPUT_VALIDATION, TARIFF_STATUS } from '@domains/Pricing/Tariffs/TariffForm/tariff.const';
import { PROFILES_TYPES } from '@domains/CRM/Users/constants/userStatus';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';

export default {
  name: 'SubscriptionFees',
  components: {
    MuiInputText,
  },
  directives: {
    Validate,
  },
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: [Object, null],
      default: null,
    },
    profile: {
      type: String,
      required: true,
    },
    generalSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alerts: {
        isFreeWithPrices: true,
        hasDefaultOrIsActive: true,
        isEditingActiveWithSubscription: true,
        isInactiveOrActiveWithoutPeriod: true,
      },
      hasSubscriptionsEnabled: false,
      isRemovingCurrentActivePeriod: false,
      subscriptions: null,
      initialSubscriptions: {
        month: {
          active: false,
          price: null,
        },
        trimester: {
          active: false,
          price: null,
        },
        semester: {
          active: false,
          price: null,
        },
        year: {
          active: false,
          price: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, ['fullCurrencyName']),
    ...mapState(DOMAINS_MODEL.pricing.tariffs, {
      tariffToEdit: state => get(state, 'tariff.data.data'),
      personalCurrentSubscriptions: state => get(state, 'tariff.data.data.profiles.personal.subscription', null),
      businessCurrentSubscriptions: state => get(state, 'tariff.data.data.profiles.business.subscription', null),
    }),
    currentSubscriptions() {
      return this.profile === PROFILES_TYPES.personal
        ? this.personalCurrentSubscriptions
        : this.businessCurrentSubscriptions;
    },
    isToggleDisabled() {
      return this.isFreeWithPrices
        || this.hasDefaultCity
        || this.isEditingActiveTariff;
    },
    isActiveTariffWithSubsAlertVisible() {
      return this.isEditingActiveTariff
        && this.hasSubscriptionsEnabled
        && this.alerts.isEditingActiveWithSubscription;
    },
    isFreeWithPrices() {
      return this.generalSettings.isFree && this.generalSettings.isPriced;
    },
    hasDefaultCity() {
      return !!find(this.generalSettings.cities, 'isDefault');
    },
    isEditingActiveTariff() {
      return get(this, 'tariffToEdit.generalSettings.status') === TARIFF_STATUS.active;
    },
    isInactiveOrActiveWithoutPeriod() {
      return [
        TARIFF_STATUS.active,
        TARIFF_STATUS.inactive,
      ].includes(this.generalSettings.status) && this.hasSubscriptionsEnabled && everyValue(this.subscriptions || {}, ['active', false]);
    },
    hasAnySubscriptionsValue() {
      const hasAnySubscriptionsValue = !everyValue(this.subscriptions || {}, ['active', false]) || null;
      return toString(hasAnySubscriptionsValue);
    },
    defaultActiveAlertText() {
      const baseText = 'You cannot <span class="emobg-font-weight-semibold">mark</span> this as a subscription fee';

      const texts = {
        isDefaultAndActive: {
          text: `${baseText} as you have a <span class="emobg-font-weight-semibold">city as a default</span> and is <span class="emobg-font-weight-semibold">active</span>`,
          value: this.hasDefaultCity && this.isEditingActiveTariff,
        },
        isDefault: {
          text: `${baseText} as you have a <span class="emobg-font-weight-semibold">city as a default</span>`,
          value: this.hasDefaultCity,
        },
        isActive: {
          text: `${baseText} because it is <span class="emobg-font-weight-semibold">active</span>`,
          value: this.isEditingActiveTariff && !this.hasSubscriptionsEnabled,
        },
      };

      return get(find(texts, 'value'), 'text', '');
    },
  },
  watch: {
    generalSettings: {
      deep: true,
      immediate: true,
      handler() {
        if (this.isFreeWithPrices || this.hasDefaultCity) {
          this.hasSubscriptionsEnabled = false;
          this.subscriptions = null;
        }
      },
    },
    subscriptions: {
      deep: true,
      handler() {
        this.$emit('change', this.subscriptions);
      },
    },
    hasSubscriptionsEnabled: {
      immediate: true,
      handler(hasSubscriptions) {
        if (!hasSubscriptions) {
          this.subscriptions = null;
        } else {
          const fallbackSubscriptions = this.model ? this.model : this.initialSubscriptions;
          this.subscriptions = cloneDeep(fallbackSubscriptions);
        }
      },
    },
  },
  created() {
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.NUMERIC_INPUT_VALIDATION = NUMERIC_INPUT_VALIDATION;
    this.hasSubscriptionsEnabled = !!this.model;
  },
  methods: {
    get,
    isEmpty,
    everyValue,
    toDecimalFormatOrNull,
    onClickCheckbox(period) {
      if (this.isEditingActiveTariff && get(this, `currentSubscriptions.${period}.active`)) {
        this.isRemovingCurrentActivePeriod = true;
      }
    },
    activateAlerts() {
      each(this.alerts, (_value, key) => { this.alerts[key] = true; });
    },
    onChangeCheckbox(isChecked, period) {
      if (!isChecked) {
        this.subscriptions[period].price = null;
      }
      this.$nextTick(() => invoke(this, `$refs.${period}Input[0].$el.MuiValidationManager.reset`));
    },
  },
};
</script>
<template>
  <div class="SubscriptionFees emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3 mb-3">
    <h3 class="mb-3">
      Subscription fees
    </h3>
    <p class="emobg-body-1 emobg-color-ink-light pb-3">
      You can set up time and price parameters for the subscription tariff
    </p>
    <span @click="activateAlerts">
      <ui-toggle
        :value="hasSubscriptionsEnabled"
        :disabled="isToggleDisabled"
        name="subscription"
        text="Mark as subscription tariff"
        data-test-id="subscription-toggle"
        @changevalue="({ detail }) => hasSubscriptionsEnabled = detail"
      />
    </span>

    <ui-alert
      v-if="isFreeWithPrices && alerts.isFreeWithPrices"
      :icon="ICONS.infoFull"
      :color="COLORS.primary"
      dismissible
      class="d-block pb-3"
      @dismissAlert="alerts.isFreeWithPrices = false"
    >
      You cannot <span class="emobg-font-weight-semibold">mark</span> this as a subscription fee as it’s a free tariff
    </ui-alert>

    <ui-alert
      v-if="defaultActiveAlertText && alerts.hasDefaultOrIsActive"
      :icon="ICONS.infoFull"
      :color="COLORS.primary"
      dismissible
      class="d-block pb-3"
      @dismissAlert="alerts.hasDefaultOrIsActive = false"
    >
      <span v-html="defaultActiveAlertText" />
    </ui-alert>

    <ui-alert
      v-if="isActiveTariffWithSubsAlertVisible"
      :icon="ICONS.infoFull"
      :color="COLORS.primary"
      dismissible
      class="d-block pb-3"
      @dismissAlert="alerts.isEditingActiveWithSubscription = false"
    >
      You cannot <span class="emobg-font-weight-semibold">unmark</span>
      this tariff with subscriptions because has users related.
    </ui-alert>

    <ui-alert
      v-if="isRemovingCurrentActivePeriod"
      :icon="ICONS.infoFull"
      :color="COLORS.danger"
      dismissible
      class="d-block pb-3"
      @dismissAlert="isRemovingCurrentActivePeriod = false"
    >
      You cannot <span class="emobg-font-weight-semibold">remove</span>
      subscription for a period type in an <span class="emobg-font-weight-semibold">active tariff</span>
    </ui-alert>

    <ui-alert
      v-if="!isEmpty(get($refs, 'hasAnySubscriptionInput.$el.MuiValidationManager.results'))
        && isInactiveOrActiveWithoutPeriod
        && alerts.isInactiveOrActiveWithoutPeriod"
      :icon="ICONS.infoFull"
      :color="COLORS.danger"
      dismissible
      class="d-block pb-3"
      @dismissAlert="alerts.isInactiveOrActiveWithoutPeriod = false"
    >
      You must provide information for <span class="emobg-font-weight-semibold">at least one period</span>
    </ui-alert>

    <div v-if="hasSubscriptionsEnabled">
      <div
        v-for="(subscription, period) in subscriptions"
        :key="period"
        class="d-flex align-items-center mb-4"
      >
        <div
          class="col-2"
          @click="() => onClickCheckbox(period)"
        >
          <ui-checkbox
            :checked="subscription.active"
            :caption="period"
            :name="`active_${period}`"
            :disabled="(get(currentSubscriptions, `${period}.active`) && isEditingActiveTariff) || false"
            class="text-capitalize ml-n2"
            @changevalue="({ detail }) => {
              subscription.active = detail;
              onChangeCheckbox(detail, period);
            }"
          />
        </div>

        <div class="col-10">
          <MuiInputText
            :ref="`${period}Input`"
            v-model="subscription.price"
            v-validate="{
              ...(subscription.active && { isRequired: true }),
              isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
              isNotZero: NUMERIC_INPUT_VALIDATION.isNotZero,
            }"
            :disabled="!subscription.active"
            :name="`price_${period}`"
            :label="`Price in ${fullCurrencyName}*`"
            placeholder="Enter numeric value"
            class="w-100"
            @blur="value => subscription.price = toDecimalFormatOrNull(value)"
          />
        </div>
      </div>
      <div
        v-if="isInactiveOrActiveWithoutPeriod"
        class="SubscriptionFees__enabled-validation"
      >
        <MuiInputText
          ref="hasAnySubscriptionInput"
          v-validate="{ isRequired: true }"
          :model-value="hasAnySubscriptionsValue"
          name="hasAnySubscriptions"
          class="d-none"
          disabled
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.SubscriptionFees {
  &__enabled-validation {
    .MuiValidation__errorLabel {
      display: none !important;
    }
  }
}
</style>
